<template>
    <div class="noticeDialog">
        <div class="noticeHeader">
            <img class="back" @click="$router.back()" src="@/assets/images/mobile1/notice_back.png">
            <div class="noticeTitle">
                <img src="@/assets/images/mobile1/notice_title.png">
            </div>
        </div>
        <div class="boxItem">
            <div class="left">
                <img src="@/assets/images/mobile1/notice_vivo.png">
                <span class="title">VIVO</span>
            </div>
            <span class="explainBtn" @click="vivoShow = true">安装说明</span>
        </div>

        <div class="boxItem">
            <div class="left">
                <img src="@/assets/images/mobile1/notice_oppo.png">
                <span class="title">OPPO</span>
            </div>
            <span class="explainBtn" @click="oppoShow = true">安装说明</span>
        </div>
        
        <div class="msg">
            <img src="@/assets/images/mobile1/notice_msg.png">
        </div>

        <div class="boxItem2">
            <div class="left">
                <img src="@/assets/images/mobile1/notice_light.png">
                <span class="title">安装Tips</span>
            </div>
            <span class="explainBtn"  @click="tipsShow = true">说明</span>
        </div>

        <div class="boxItem2">
            <div class="left">
                <img src="@/assets/images/mobile1/notice_arrow.png">
                <span class="title">解除风险流程</span>
            </div>
            <span class="explainBtn" @click="riskShow = true">说明</span>
        </div>

        <div class="boxItem3">
            <span>官方邮箱</span>
            <div class="linkBtn">
                <img @click="toTg" src="@/assets/images/mobile1/link_tg.png">
                <img @click="toPotato" src="@/assets/images/mobile1/link_potato.png">
                <img @click="toQQ" src="@/assets/images/mobile1/link_qq.png">
            </div>
            <span>如下载和安装异常，请点击留言反馈</span>
        </div>

        <!-- vivo 安装说明 -->
        <van-overlay :show="vivoShow" v-if="vivoShow" class="vivoOverlay">
            <div class="wrapper" @click.stop>
                <div class="head">
                    <img @click="vivoShow = false" class="back" src="@/assets/images/mobile1/notice_back.png">
                    <span>VIVO手机安装说明</span>
                </div>
                <div class="content">
                    <div class="logoTitle">
                        <img src="@/assets/images/mobile1/vivo_0.png">
                        <span>VIVO</span>
                    </div>
                    <div class="overlayBoxItem">
                        <span>1.点击-无视风险安装</span>
                        <div class="overlayBoxImg">
                            <img src="@/assets/images/mobile1/vivo_1.png">
                        </div>
                    </div>
                    <div class="overlayBoxItem">
                        <span>2.点击-安装</span>
                        <div class="overlayBoxImg">
                            <img src="@/assets/images/mobile1/vivo_2.png">
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>

        <!-- oppo 安装说明 -->
        <van-overlay :show="oppoShow" v-if="oppoShow" class="vivoOverlay">
            <div class="wrapper" @click.stop>
                <div class="head">
                    <img @click="oppoShow = false" class="back" src="@/assets/images/mobile1/notice_back.png">
                    <span>OPPO手机安装说明</span>
                </div>
                <div class="content">
                    <div class="logoTitle">
                        <img src="@/assets/images/mobile1/oppo_0.png">
                        <span>OPPO</span>
                    </div>
                    
                    <div class="overlayBoxItem">
                        <span>1.点击-下载</span>
                        <div class="overlayBoxImg">
                            <img src="@/assets/images/mobile1/oppo_1.png">
                        </div>
                    </div>
                    
                    <div class="overlayBoxItem">
                        <span>2.点击-无视风险安装</span>
                        <div class="overlayBoxImg">
                            <img src="@/assets/images/mobile1/oppo_2.png">
                        </div>
                    </div>

                    <div class="overlayBoxItem">
                        <span>3.点击-安装</span>
                        <div class="overlayBoxImg">
                            <img src="@/assets/images/mobile1/oppo_3.png">
                        </div>
                    </div>

                    <div class="overlayBoxItem">
                        <span>4.点击-打开应用</span>
                        <div class="overlayBoxImg">
                            <img src="@/assets/images/mobile1/oppo_4.png">
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>

        <!-- 其他异常Tips -->
        <van-overlay :show="tipsShow" v-if="tipsShow" class="vivoOverlay">
            <div class="wrapper" @click.stop>
                <div class="head">
                    <img @click="tipsShow = false" class="back" src="@/assets/images/mobile1/notice_back.png">
                    <span>其他异常Tips</span>
                </div>
                <div class="content">

                    <div class="overlayBoxItem" style="margin-top: 80px">
                        <span style="padding-bottom: 30px">异常Tips处理办法</span>
                        <div class="overlayBoxImg">
                            <img src="@/assets/images/mobile1/tips_1.png">
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>

        <!-- 解除风险 -->
        <van-overlay :show="riskShow" v-if="riskShow" class="vivoOverlay">
            <div class="wrapper" @click.stop>
                <div class="head">
                    <img @click="riskShow = false" class="back" src="@/assets/images/mobile1/notice_back.png">
                    <span>解除风险</span>
                </div>
                <div class="content" style="padding-top: 70px">
                    <div class="overlayBoxItem">
                        <span>1.打开手机管家，点击-病毒查杀</span>
                        <div class="overlayBoxImg">
                            <img src="@/assets/images/mobile1/risk_1.png">
                        </div>
                    </div>

                    <div class="overlayBoxItem">
                        <span>2.点击-风险管理中心-查看</span>
                        <div class="overlayBoxImg">
                            <img src="@/assets/images/mobile1/risk_2.png">
                        </div>
                    </div>

                    <div class="overlayBoxItem">
                        <span>3.点击-情色五月天</span>
                        <div class="overlayBoxImg">
                            <img src="@/assets/images/mobile1/risk_3.png">
                        </div>
                    </div>

                    <div class="overlayBoxItem">
                        <span>4.左下角点击-解除管控</span>
                        <div class="overlayBoxImg">
                            <img src="@/assets/images/mobile1/risk_4.png">
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    name: "NoticeDialog",
    data(){
        return {
            vivoShow: false,
            oppoShow: false,
            tipsShow: false,
            riskShow: false,
        }
    },
    methods:{
        toPotato() {
            window.open('https://ptcc.in/wuyuetian_guanfangqun', '_blank');
        },
        toTg() {
            window.open('https://t.me/qs5yt_app', '_blank');
        },
        toQQ(){
            window.open('https://qm.qq.com/cgi-bin/qm/qr?k=HIuvbG0sBu9PZBOOnlhMIIEh-o2VyGAQ&noverify=0', '_blank');
        }
    }
}
</script>

<style lang="scss" scoped>
.noticeDialog {
    width: 100%;
    height: 100%;
    background: #060f21;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 0 10px;
    
    
    .noticeHeader{
        margin-bottom: 10px;
        
        .back{
            position: absolute;
            height: 20px;
            top:30px;
            left: 10px;
        }
        
        .noticeTitle{
            width: 100%;
            display: flex;
            justify-content: center;
            
            img{
                height: 60px;
                margin-top: 20px;
            }
        }
    }
    
    .boxItem{
        background: #0d234a;
        padding: 10px 25px;
        border-radius: 7.5px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 16px;
        //text-decoration: underline;
        color: #fff;
        
        .left{
            display: flex;
            align-items: center;
            
            .title{
                margin-left: 5px;
            }
        }
        
        img{
            width: 44px;
        }
        
        .explainBtn{
            text-decoration: underline;
        }
    }

    .boxItem2{
        background: #0d234a;
        padding: 15px 25px;
        border-radius: 7.5px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 16px;
        //text-decoration: underline;
        color: #fff;

        .left{
            display: flex;
            align-items: center;

            .title{
                margin-left: 10px;
            }
        }

        img{
            width: 15px;
        }

        .explainBtn{
            padding: 5px 25px;
            background: linear-gradient(91deg,#ffdb62,#ff9300);
            color: #0d234a;
            border-radius: 50px;
        }
    }

    .boxItem3{
        background: #0d234a;
        padding: 15px 25px;
        border-radius: 7.5px;
        margin-bottom: 25px;
        //font-weight: 700;
        font-size: 16px;
        //text-decoration: underline;
        display: flex;
        flex-flow: column;
        align-items: center;
        color: #fff;
        
        .linkBtn{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            img{
                width: 30%;
                height: 40px;
            }
        }
        

    }
    
    .msg{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
        
        img{
            width: 95%;
        }
    }
    
    .vivoOverlay{
        
        .wrapper{
            width: 100%;
            height: 100%;
            background: #060f21;
            //padding: 0 10px;
            
            .head{
                text-align: center;
                position: fixed;
                left: 0;
                top: 0;
                line-height: 50px;
                font-size: 15px;
                color: #ffffff;
                width: 100%;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #060f21;
                
                .back{
                    position: absolute;
                    height: 20px;
                    top: 15px;
                    left: 20px;
                }
            }
            
            .content{
                overflow-y: auto;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
                height: 100%;
                //padding-top: 50px;
                padding-bottom: 30px;
                
                .logoTitle{
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 60px;
                    margin-bottom: 10px;

                    font-size: 15px;
                    color: #ffffff;
                    font-weight: bold;
                    
                    img{
                        margin: 20px 0 10px;
                        height: 60px;
                        width: 60px;
                    }
                }
                
                .overlayBoxItem{
                    font-size: 16px;
                    color: #ffffff;
                    font-weight: bold;
                    padding: 0 20px;
                    display: flex;
                    flex-direction: column;
                    
                    .overlayBoxImg{
                        display: flex;
                        justify-content: center;
                        margin-top: 15px;
                        
                        img{
                            width:85%;
                            margin: 0 auto 25px;
                        }
                    }
                }
            }
        }
    }
}
</style>